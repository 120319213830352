<template>
  <div>
    <ModalDeletePurchasing
      v-if="showDeleteModal"
      :selectedItem="selectedItem"
      @closeModal="closeModal"
    />
    <div class="table-responsive table-scrollable">
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th class="text-center">Versão</th>
            <th>Centro de Custo</th>
            <th>Natureza</th>
            <th class="text-center">Competência</th>
            <th class="text-center">Custo Unitário</th>
            <th class="text-center" v-if="!disableEdit">Excluir</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in registers" :key="key">
            <td class="text-center">
              <span
                v-if="item.REVISAR"
                class="bg-yellow bg-opacity-10 text-warning lh-1 rounded-pill p-2"
              >
                <i class="ph-warning"></i>
              </span>
              <span
                v-else
                class="bg-primary bg-opacity-10 text-primary lh-1 rounded-pill p-2"
              >
                <span class="letter-icon"> {{ item.VERSAO }}</span>
              </span>
            </td>
            <td>{{ item.CODCCUSTO }} - {{ item.CENTRODECUSTO }}</td>
            <td>{{ item.CODTBORCAMENTO }} - {{ item.NATUREZA }}</td>
            <td class="text-center">{{ item.MES }}</td>
            <td class="text-center">
              {{ formatToCurrencyBr(item.CUSTOUNITARIO) }}
            </td>
            <td class="text-center p-0" v-if="!disableEdit">
              <button
                class="btn btn-danger btn-sm"
                @click="deleteComprasRegister(item)"
              >
                <i class="ph ph-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import ModalDeletePurchasing from '@/components/forms/purchasing/ModalDeletePurchasing.vue'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'RegisterPurchasing',

  props: {
    form: {
      type: Object,
      required: true,
    },
    registers: {
      type: Array,
      required: true,
    },
  },
  components: {
    ModalDeletePurchasing,
  },
  data() {
    return {
      selectedItem: null,
      dataByMonths: [],
      formatToCurrencyBr,
      showDeleteModal: false,
    }
  },
  emits: ['updateForms'],
  computed: {
    disableEdit() {
      return this.form.STATUS_FORMULARIO_ETAPA === 1
    },
  },
  methods: {
    deleteComprasRegister(item) {
      this.selectedItem = item
      this.showDeleteModal = true
    },
    closeModal(needReload) {
      this.showDeleteModal = false
      if (needReload) {
        this.$emit('updateForms')
      }
    },
  },
}
</script>
