<template>
  <div id="modalRemoveCollaborator" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="removeCollaborator">
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <p>Nome: {{ collaborator.NOME }}</p>
                <p>Cargo: {{ collaborator.CARGO }}</p>
              </div>
              <div class="col-sm-12">
                <label class="form-label">
                  Mês: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-select"
                  :class="{
                    'is-invalid': v$.month.$error,
                  }"
                  v-model="v$.month.$model"
                >
                  <option value="" selected disabled>
                    Selecione uma opção
                  </option>
                  <option :value="m.value" v-for="m in months" :key="m.value">
                    {{ m.name }}
                  </option>
                </select>
                <span
                  v-if="v$.month.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 pt-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Cadastrar"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>{{ title }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'
import { showModal, hideModal } from '@/utils/modal'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'RemovePersonalCostManager',
  props: {
    collaborator: {
      type: Object,
      default: null,
    },
    stepId: {
      type: Number,
      required: true,
    },
    months: {
      type: Array,
      default: null,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      title: 'Demitir Colaborador',
      formatToCurrencyBr,
      month: null,
      disableButton: false,
    }
  },

  methods: {
    async removeCollaborator() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          let response = null
          response = await this.$store.dispatch('removeCollaborator', {
            form: this.form,
            collaborator: this.collaborator,
            month: this.month,
            stepId: this.stepId,
            companyCode: this.$route.params.code,
          })
          hideModal()
          this.$root.$refs.notification.success(response.message)
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },
  validations() {
    return {
      month: {
        required,
      },
    }
  },
  mounted() {
    showModal('modalRemoveCollaborator')
  },
}
</script>
