<template>
  <div id="modalUpladFile" class="modal fade" tabindex="-1">
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Anexos:</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>

        <div class="modal-body">
          <LoadingData v-if="isLoadingData" />
          <div class="table-responsible" v-else>
            <table class="table">
              <thead>
                <tr>
                  <th>Arquivo</th>
                  <th>Data</th>
                  <th>Responsável</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="attachment in attachments" :key="attachment.id">
                  <td>{{ attachment.ARQUIVO }}</td>
                  <td>{{ attachment.CRIADOEM }}</td>
                  <td>{{ attachment.CRIADOPOR }}</td>
                  <td>
                    <a
                      :href="`${apiBaseUrl}/uploads/attachments/${companyCode}/${attachment.IDFORMULARIOETAPA}/${attachment.ARQUIVO}`"
                      download
                      target="_blank"
                      class="btn btn-link"
                      title="Download"
                    >
                      <i class="ph-download"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer border-top-0 pt-0">
          <button
            type="button"
            class="btn btn-link"
            data-bs-dismiss="modal"
            title="Cancelar"
            @click="$emit('closeModal', false)"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { showModal, hideModal } from '@/utils/modal'
import LoadingData from '@/components/commons/globals/LoadingData.vue'

export default {
  name: 'ModalAttachment',
  props: {
    companyCode: {
      type: String,
      required: true,
    },
    formStepId: {
      type: Number,
      required: true,
    },
  },
  components: {
    LoadingData,
  },
  data() {
    return {
      modalSize: 'modal-lg',
      attachments: [],
      isLoadingData: false,
      apiBaseUrl: process.env.VUE_APP_BASE_URL_API,
    }
  },
  watch: {
    fileContent() {
      this.disableButton = !this.fileContent
    },
  },
  methods: {
    async getAllAttachments() {
      try {
        this.isLoadingData = true
        this.attachments = await this.$store.dispatch('getAllAttachments', {
          formStepId: this.formStepId,
          companyCode: this.companyCode,
        })
        this.isLoadingData = false
      } catch (error) {
        this.isLoadingData = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getAllAttachments()
    showModal('modalUpladFile')
  },
}
</script>
