<template>
  <div id="modalDeleteFixedCost" class="modal fade" tabindex="-1">
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Excluir Registro</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="deleteFixedCostForm">
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-12">
                <p>
                  Confirma a exclusão do {{ selectedItem.CENTRODECUSTO }} e
                  {{ selectedItem.NATUREZA }} para o período o mês
                  {{ selectedItem.MES }} no valor de {{ selectedItem.VALOR }}?
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 pt-0">
            <button
              type="button"
              class="btn btn-link text-danger"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-danger"
              title="Confirmar"
              :disabled="disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>Excluir</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style>
.table-content {
  max-height: 300px;
  overflow-y: auto;
}
</style>
<script>
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'ModalDeleteFixedCost',
  props: {
    selectedItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalSize: 'modal-sm',
      disableButton: false,
    }
  },
  emits: ['closeModal'],
  methods: {
    async deleteFixedCostForm() {
      try {
        this.disableButton = true
        const data = await this.$store.dispatch('deleteFixedCostForm', {
          id: this.selectedItem.IDGERCF,
          companyCode: this.selectedItem.CODUNIDADE,
        })
        this.$root.$refs.notification.success(data.message)
        hideModal('modalDeleteFixedCost')
        this.$emit('closeModal', true)
      } catch (error) {
        console.error(error)
        this.disableButton = false
      }
    },
  },

  mounted() {
    showModal('modalDeleteFixedCost')
  },
}
</script>
