<template>
  <div class="">
    <LoadingData v-if="isLoadingData" />
    <div v-else class="row">
      <div class="card-body col-12">
        <h6 class="mb-1 text-center text-primary">Contratações</h6>
      </div>
      <div class="card-body" v-if="report.newColaborators.lenght === 0">
        <p class="text-center">Nenhuma contratação</p>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Função</th>
              <th>Centro de Custo</th>
              <th>Seção</th>
              <th>Mês</th>
              <th>Salário</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="colaborador in report.newColaborators"
              :key="colaborador.IDGERCPC"
            >
              <td>{{ colaborador.FUNCAO }}</td>
              <td>
                {{ colaborador.CODCCUSTO }} - {{ colaborador.CENTRODECUSTO }}
              </td>
              <td>{{ colaborador.CODSECAO }} - {{ colaborador.SECAO }}</td>
              <td>{{ colaborador.MES }}</td>
              <td>{{ formatToCurrencyBr(colaborador.SALARIO) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body col-12">
        <h6 class="mb-1">Demissões:</h6>
      </div>
      <div class="card-body" v-if="report.demissions.length === 0">
        <p class="text-center">Nenhuma contratação</p>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Função</th>
              <th>Centro de Custo</th>
              <th>Seção</th>
              <th>Mês</th>
              <th>Salário</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="colaborador in report.demissions"
              :key="colaborador.IDGERCPC"
            >
              <td>{{ colaborador.FUNCAO }}</td>
              <td>
                {{ colaborador.CODCCUSTO }} - {{ colaborador.CENTRODECUSTO }}
              </td>
              <td>{{ colaborador.CODSECAO }} - {{ colaborador.SECAO }}</td>
              <td>{{ colaborador.MES }}</td>
              <td>{{ formatToCurrencyBr(colaborador.SALARIO) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body col-12">
        <h6 class="mb-1">Promoções:</h6>
      </div>
      <div class="card-body" v-if="report.promotions.length === 0">
        <p class="text-center">Nenhuma promoção</p>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Função</th>
              <th>Centro de Custo</th>

              <th>Mês</th>
              <th>Salário</th>
              <th>Novo Salário</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="colaborador in report.promotions"
              :key="colaborador.IDGERCPM"
            >
              <td>{{ colaborador.CARGO }}</td>
              <td>
                {{ colaborador.NOME }}
              </td>
              <td>{{ colaborador.MES }}</td>
              <td>{{ formatToCurrencyBr(colaborador.SALARIO) }}</td>
              <td>{{ formatToCurrencyBr(colaborador.NOVOSALARIO) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body col-12">
        <h6 class="mb-1">Manter:</h6>
      </div>
      <div class="card-body" v-if="report.keepColaborators.length === 0">
        <p class="text-center">Nenhuma persistencia</p>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Função</th>
              <th>Centro de Custo</th>

              <th>Salário</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="colaborador in report.keepColaborators"
              :key="colaborador.IDGERCPM"
            >
              <td>{{ colaborador.CARGO }}</td>
              <td>
                {{ colaborador.NOME }}
              </td>
              <td>{{ formatToCurrencyBr(colaborador.SALARIO) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'ReportCollaborador',
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  components: {
    LoadingData,
  },
  data() {
    return {
      formatToCurrencyBr,
      isLoadingData: false,
      months: [
        { value: '01', name: 'Janeiro' },
        { value: '02', name: 'Fevereiro' },
        { value: '03', name: 'Março' },
        { value: '04', name: 'Abril' },
        { value: '05', name: 'Maio' },
        { value: '06', name: 'Junho' },
        { value: '07', name: 'Julho' },
        { value: '08', name: 'Agosto' },
        { value: '09', name: 'Setembro' },
        { value: '10', name: 'Outubro' },
        { value: '11', name: 'Novembro' },
        { value: '12', name: 'Dezembro' },
      ],
    }
  },
  emits: ['updateForms'],

  methods: {},
}
</script>
