<template>
  <div id="modalUpladFile" class="modal fade" tabindex="-1">
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Selecione um arquivo</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="sendData">
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-12">
                <p>Selecione apenas um arquivo por vez.</p>
              </div>
              <div class="col-12">
                <label class="form-label">
                  Anexo:
                  <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                  <input
                    type="file"
                    class="form-control"
                    multiple="false"
                    @change="addFile"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 pt-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Confirmar"
              :disabled="disableButton"
            >
              <span>Enviar Anexo</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'UploadAttachment',
  props: {
    companyCode: {
      type: String,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalSize: 'modal-sm',
      file: null,
      fileContent: null,
      disableButton: true,
    }
  },
  watch: {
    fileContent() {
      this.disableButton = !this.fileContent
    },
  },
  methods: {
    addFile(event) {
      const { files } = event.target
      this.files = files

      // eslint-disable-next-line no-restricted-syntax
      for (const file of this.files) {
        this.file = file.name

        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
          this.fileContent = fileReader.result
        })

        fileReader.readAsDataURL(file)
      }
    },
    async sendData() {
      try {
        this.disableButton = true
        const data = await this.$store.dispatch('addAttachToForm', {
          form: this.form,
          fileContent: this.fileContent,
          fileName: this.file,
          companyCode: this.companyCode,
        })
        this.$root.$refs.notification.success(data.message)
        hideModal('modalUpladFile')
        this.$emit('closeModal', false)
        this.disableButton = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    showModal('modalUpladFile')
  },
}
</script>
