const validateHeader = (data, fieldsMap) => {
  try {
    const headers = Object.keys(data[0])
    const fields = Object.values(fieldsMap)
    const missingFields = fields.filter((field) => !headers.includes(field))
    if (missingFields.length === 0) {
      return {
        status: true,
        message: 'Cabeçalho do arquivo válido',
      }
    }
    return {
      status: false,
      message: `Os seguintes campos estão faltando no arquivo: ${missingFields.join(
        ', ',
      )}`,
    }
  } catch (e) {
    if (e.message === 'headers.reduce is not a function') {
      return {
        status: false,
        message:
          'Cabeçalho do arquivo inválido. Por favor, verifique se o arquivo está no formato correto.',
      }
    }
    return {
      status: false,
      message: e.message,
    }
  }
}
const atributeValues = (dataSet, fieldsMap) => {
  try {
    console.log(dataSet)
    const keyVisualName = Object.keys(dataSet[0])
    const keyStructureName = Object.keys(fieldsMap)
    const valueStructureName = Object.values(fieldsMap)
    // Replace the keyVisualName with the keyStructureName for dataset
    const mappedData = dataSet.map((data) => {
      const obj = {}
      valueStructureName.forEach((field, index) => {
        obj[keyStructureName[index]] = data[keyVisualName[index]]
      })
      return obj
    })
    return {
      status: true,
      data: mappedData,
    }
  } catch (e) {
    return {
      status: false,
      message: e.message,
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export { validateHeader, atributeValues }
