<template>
  <div class="card">
    <div class="card-header p-1">
      <h4 class="mb-0 ms-2 text-primary text-center">Custos Trabalhistas</h4>
      <p class="opacity-50 text-center">
        {{
          disableEdit ? 'Preenchimento Finalizado' : 'Aguardando Preenchimento'
        }}
      </p>
    </div>
    <ul class="nav nav-tabs nav-tabs-highlight nav-justified" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          href="#tab-form-labor-costs"
          class="nav-link active"
          data-bs-toggle="tab"
          aria-selected="true"
          role="tab"
        >
          <div class="d-flex align-items-center">
            <i
              class="ph-user-plus ph-lg bg-primary text-white rounded p-2 me-3"
            ></i>
            <div>
              <div class="fw-semibold">Formulário</div>
              <span class="opacity-50"
                >Cadastre orçamento dos custos trabalhistas</span
              >
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          href="#tab-registers-labor-costs"
          ref="tabRegistersLaborCosts"
          class="nav-link"
          data-bs-toggle="tab"
          role="tab"
        >
          <div class="d-flex">
            <i
              class="ph-sort-ascending ph-lg bg-primary text-white rounded p-2 me-3"
            ></i>
            <div>
              <div class="fw-semibold">Dados Inseridos</div>
              <span class="opacity-50">Editar dados do compras</span>
            </div>
          </div>
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane active fade show"
        id="tab-form-labor-costs"
        role="tabpanel"
      >
        <div class="card-body">
          <form @submit.prevent="saveFormLaborCost">
            <div>
              <UploadExcelFile
                v-if="showModalUploadExcel"
                @closeModal="closeModal"
                @importData="importData"
              ></UploadExcelFile>
              <DownloadExcelFile
                v-if="showDownloadExcelFile"
                @finish="showDownloadExcelFile = false"
                :fieldsMap="fieldsMap"
                :link="form.LINK"
                :name="'modelo_preenchimento_custos_trabalhistas'"
              />
              <ModalViewDraft
                v-if="showModalViewDraft"
                @closeModal="closeModal"
              />
              <UploadAttachment
                v-if="showModalUploadAttachment"
                :companyCode="companyCode"
                :form="form"
                @closeModal="closeModal"
              ></UploadAttachment>
              <ModalAttachment
                v-if="showModalAttachments"
                :companyCode="companyCode"
                :formStepId="form.IDFORMETAPA"
                @closeModal="closeModal"
              ></ModalAttachment>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 mb-3">
                <label class="form-label">Centro de Custo:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  :class="{
                    'is-invalid': v$.formItem.item.$errors.length,
                  }"
                  v-model="v$.formItem.item.$model"
                >
                  <option value="" disabled>Selecione uma opção</option>
                  <option
                    v-for="CC in COSTCENTERS"
                    :key="CC.CODCCUSTO"
                    :value="CC"
                  >
                    {{ CC.CODCCUSTO }} - {{ CC.CENTRODECUSTO }}
                  </option>
                </select>

                <span
                  v-if="v$.formItem.item.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label class="form-label">Natureza:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  :class="{
                    'is-invalid': v$.formItem.itemNat.$errors.length,
                  }"
                  v-model="v$.formItem.itemNat.$model"
                >
                  <option value="" disabled>Selecione uma opção</option>
                  <option
                    v-for="NAT in NATURES"
                    :key="NAT.CODNATUREZA"
                    :value="NAT"
                  >
                    {{ NAT.CODNATUREZA }} - {{ NAT.NATUREZA }}
                  </option>
                </select>

                <span
                  v-if="v$.formItem.itemNat.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <label class="form-label">Valor:</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  placeholder="Valor por Colaborador"
                  :class="{
                    'is-invalid': v$.formItem.custoUnitario.$errors.length,
                  }"
                  v-model="v$.formItem.custoUnitario.$model"
                />
                <span
                  v-if="v$.formItem.custoUnitario.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label class="form-label">Competência:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  v-model="formItem.competencia"
                >
                  <option
                    v-for="month in months"
                    :key="month.value"
                    :value="month.value"
                  >
                    {{ month.name }}
                  </option>
                </select>
              </div>
              <div class="col-12 mb-1" v-if="!disableEdit">
                <div class="d-flex justify-content-between">
                  <div>
                    <button
                      type="button"
                      class="btn btn-yellow me-2"
                      @click="showModalViewDraft = true"
                    >
                      <i class="ph-calculator me-2"></i> Draft
                    </button>
                    <div class="btn-group me-2" v-if="!disableEdit">
                      <button type="button" class="btn btn-success" disabled>
                        <i class="ph ph-file-xls me-2"></i>Planilha
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        title="Download do modelo de planilha para importação"
                        @click="showDownloadExcelFile = true"
                      >
                        <i class="ph-download"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        title="Realiza o upload da planilha preenchida"
                        @click="showModalUploadExcel = true"
                      >
                        <i class="ph-upload"></i>
                      </button>
                    </div>
                    <div class="btn-group me-2">
                      <button type="button" class="btn btn-secondary" disabled>
                        <i class="ph ph-paperclip-horizontal me-2"></i>Anexos
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary"
                        title="Anexar um novo arquivo"
                        @click="showModalUploadAttachment = true"
                      >
                        <i class="ph-paperclip"></i>
                      </button>
                      <button
                        v-if="!disableEdit"
                        type="button"
                        class="btn btn-secondary"
                        title="Consultar anexos"
                        @click="showModalAttachments = true"
                      >
                        <i class="ph-eye"></i>
                      </button>
                    </div>
                  </div>

                  <button
                    type="submit"
                    class="btn btn-primary ms-2"
                    :disabled="v$.$invalid || disableButton"
                  >
                    <i class="ph-floppy-disk me-2"></i> Cadastrar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="tab-pane fade" id="tab-registers-labor-costs" role="tabpanel">
        <LoadingData
          v-if="isLoadingRegisters"
          :message="'Carregando custos trabalhistas...'"
        />
        <div v-else>
          <RegisterLaborCost
            :registers="registers"
            :companyCode="companyCode"
            :form="form"
            :stepId="stepId"
            @updateForms="finishProcess"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minValue, sameAs } from '@vuelidate/validators'

import LoadingData from '@/components/commons/globals/LoadingData.vue'
import RegisterLaborCost from '@/components/forms/laborCost/RegisterLaborCost.vue'
import UploadExcelFile from '@/components/forms/UploadExcelFile.vue'
import DownloadExcelFile from '@/components/forms/DownloadExcelFile.vue'
import ModalViewDraft from '@/components/forms/draft/ModalViewDraft.vue'
import UploadAttachment from '@/components/forms/UploadAttachment.vue'
import ModalAttachment from '@/components/forms/ModalAttachment.vue'
import { validateHeader, atributeValues } from '@/utils/upload'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'FormCustosTrabalhistas',
  props: {
    stepId: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    RegisterLaborCost,
    UploadExcelFile,
    DownloadExcelFile,
    LoadingData,
    ModalViewDraft,
    UploadAttachment,
    ModalAttachment,
  },
  data() {
    return {
      pageTitle: 'DP - Custos Trabalhistas',
      formatToCurrencyBr,
      // Modais
      showModalUploadExcel: false,
      showDownloadExcelFile: false,
      showModalViewDraft: false,
      showModalUploadAttachment: false,
      showModalAttachments: false,
      // Loading
      isLoadingCostCenters: true,
      isLoadingRegisters: true,
      disableButton: false,
      fieldsMap: {
        centroCustoCodigo: 'Código Centro de Custo',
        centroCustoNome: 'Centro de Custo',
        naturezaCodigo: 'Código Natureza',
        naturezaNome: 'Natureza',
        competencia: 'Competência',
        custoUnitario: 'Valor Por Colaborador',
      },
      months: [
        { value: '', name: 'Anual' },
        { value: '01', name: 'Janeiro' },
        { value: '02', name: 'Fevereiro' },
        { value: '03', name: 'Março' },
        { value: '04', name: 'Abril' },
        { value: '05', name: 'Maio' },
        { value: '06', name: 'Junho' },
        { value: '07', name: 'Julho' },
        { value: '08', name: 'Agosto' },
        { value: '09', name: 'Setembro' },
        { value: '10', name: 'Outubro' },
        { value: '11', name: 'Novembro' },
        { value: '12', name: 'Dezembro' },
      ],
      formItem: {
        item: null,
        centroCustoCodigo: '',
        centroCustoNome: '',
        naturezaCodigo: '',
        naturezaNome: '',
        custoUnitario: '',
        competencia: '',
      },
      formItems: [],
      registers: [],
      COSTCENTERS: [],
      NATURES: [],
    }
  },
  emits: ['updateForms'],
  watch: {
    'formItem.item': {
      handler() {
        this.NATURES = this.formItem.item?.NATUREZAS || []
      },
      deep: true,
    },
  },
  computed: {
    disableEdit() {
      return this.form.STATUS_FORMULARIO_ETAPA === 1
    },
  },
  methods: {
    finishProcess(needReload) {
      this.$emit('updateForms')
      if (needReload) {
        this.getLaborCostBudget()
      }
    },
    importData(data) {
      const res = validateHeader(data, this.fieldsMap)
      if (!res.status) {
        this.$root.$refs.notification.error(res.message)
        return false
      }
      const values = atributeValues(data, this.fieldsMap)
      if (!values.status) {
        this.$root.$refs.notification.error(values.message)
        return false
      }
      this.formItems = values.data
      this.save()
      this.$refs.tabRegistersLaborCosts.click()
      this.showModalUploadExcel = false
    },
    closeModal() {
      this.showModalUploadExcel = false
      this.showDownloadExcelFile = false
      this.showModalViewDraft = false
      this.showModalUploadAttachment = false
      this.showModalAttachments = false
    },
    downloadTemplateDraftXls() {
      window.open(this.linkTemplateDraftXls, '_blank')
    },
    async getCenterCostsLaborCost() {
      try {
        this.isLoadingCostCenters = true
        this.COSTCENTERS = await this.$store.dispatch(
          'getCenterCostsLaborCost',
          this.companyCode,
        )
        this.isLoadingCostCenters = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getLaborCostBudget() {
      try {
        this.isLoadingRegisters = true
        this.registers = await this.$store.dispatch(
          'getLaborCostBudget',
          this.companyCode,
        )
        this.isLoadingRegisters = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async saveFormLaborCost() {
      if (!this.v$.$invalid) {
        this.formItem.centroCustoCodigo = this.formItem.item.CODCCUSTO
        this.formItem.centroCustoNome = this.formItem.item.CENTRODECUSTO
        this.formItem.naturezaCodigo = this.formItem.itemNat.CODNATUREZA
        this.formItem.naturezaNome = this.formItem.itemNat.NATUREZA
        this.formItems = [this.formItem]
        this.save()
      }
    },
    async save() {
      try {
        this.disableButton = true
        const data = await this.$store.dispatch('saveFormLaborCost', {
          form: {
            stepId: this.stepId,
            formStepId: this.form.IDFORMETAPA,
            formId: this.form.IDFORMULARIO,
            formItems: this.formItems,
          },
          companyCode: this.companyCode,
        })
        this.$root.$refs.notification.success(data.message)
        this.formItem = {
          item: null,
          centroCustoCodigo: '',
          centroCustoNome: '',
          naturezaCodigo: '',
          naturezaNome: '',
          custoUnitario: '',
        }
        this.getLaborCostBudget()
        this.disableButton = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  validations() {
    return {
      formItem: {
        itemNat: {
          required,
        },
        item: {
          required,
        },
        custoUnitario: {
          required,
        },
      },
    }
  },
  mounted() {
    this.getLaborCostBudget()
    this.getCenterCostsLaborCost()
  },
}
</script>
