<template>
  <div>
    <ModalDeleteCommercial
      v-if="showDeleteModal"
      :selectedClient="selectedClient"
      @closeModal="closeModal"
    />
    <div class="table-responsive table-scrollable">
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th class="text-center">Versão</th>
            <th>Cliente</th>
            <th class="text-center">Competência</th>
            <th class="text-center">Volume</th>
            <th class="text-center">Preço Médio</th>
            <th class="text-center">Valor Total</th>
            <th class="text-center" v-if="!disableEdit">Excluir</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="client in registers" :key="client.cliente">
            <td class="text-center">
              <span
                v-if="client.REVISAR"
                class="bg-yellow bg-opacity-10 text-warning lh-1 rounded-pill p-2"
              >
                <i class="ph-warning"></i>
              </span>
              <span
                v-else
                class="bg-primary bg-opacity-10 text-primary lh-1 rounded-pill p-2"
              >
                <span class="letter-icon"> {{ client.VERSAO }}</span>
              </span>
            </td>
            <td>{{ client.NOME }}</td>
            <td class="text-center">{{ client.MES }}</td>
            <td class="text-center">{{ client.VOLUME }}</td>
            <td class="text-center">
              {{ formatToCurrencyBr(client.PRECOMEDIO) }}
            </td>
            <td class="text-center">
              {{ formatToCurrencyBr(client.VALORTOTAL) }}
            </td>
            <td class="text-center p-0" v-if="!disableEdit">
              <button
                class="btn btn-danger btn-sm"
                @click="deleteComercialRegister(client)"
              >
                <i class="ph ph-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import ModalDeleteCommercial from '@/components/forms/commercial/ModalDeleteCommercial.vue'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'RegisterComercial',

  props: {
    form: {
      type: Object,
      required: true,
    },
    stepId: {
      type: Number,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
    registers: {
      type: Array,
      required: true,
    },
  },
  components: {
    ModalDeleteCommercial,
  },
  data() {
    return {
      selectedClient: null,
      formatToCurrencyBr,
      showDeleteModal: false,
    }
  },
  emits: ['updateForms'],
  computed: {
    disableEdit() {
      return this.form.STATUS_FORMULARIO_ETAPA === 1
    },
  },
  methods: {
    deleteComercialRegister(client) {
      this.selectedClient = client
      this.showDeleteModal = true
    },
    closeModal(needReload) {
      this.showDeleteModal = false
      if (needReload) {
        this.$emit('updateForms', false)
      }
    },
  },
}
</script>
