<template>
  <div id="modalViewDraft" class="modal fade" tabindex="-1">
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Draft</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <div class="modal-body">
          <LoadingData v-if="isLoadingDraftInitial" />
          <div v-else>
            <p><strong>Volume:</strong> {{ formatToNumber(draft.volume) }}</p>
            <p>
              <strong>Receita Bruta:</strong>
              {{ formatToCurrencyBr(draft.receitaBruta) }}
            </p>
            <p>
              <strong>Deduções:</strong>
              {{ formatToCurrencyBr(draft.deducoes) }}
            </p>
            <p>
              <strong>Receita Líquida:</strong>
              {{ formatToCurrencyBr(draft.receitaLiquida) }}
            </p>
            <p>
              <strong>Custos Variáveis:</strong>
              {{ formatToCurrencyBr(draft.custosVariaveis) }}
            </p>
            <p>
              <strong>Margem de Contribuição:</strong>
              {{ formatToCurrencyBr(draft.margemContribuicao) }}
            </p>
            <p>
              <strong>Custos Fixos:</strong>
              {{ formatToCurrencyBr(draft.custosFixos) }}
            </p>
            <p>
              <strong>Ebitda:</strong>
              {{ formatToCurrencyBr(draft.ebitda) }}
            </p>
          </div>
        </div>
        <div class="modal-footer border-top-0 pt-0">
          <button
            type="button"
            class="btn btn-link"
            data-bs-dismiss="modal"
            title="Cancelar"
            @click="$emit('closeModal', false)"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { showModal, hideModal } from '@/utils/modal'
import { formatToCurrencyBr, formatToNumber } from '@/utils/currency'
import LoadingData from '@/components/commons/globals/LoadingData.vue'

export default {
  name: 'ModalViewDraft',
  data() {
    return {
      modalSize: 'modal-md',
      isLoadingDraftInitial: true,
      draft: null,
      formatToCurrencyBr,
      formatToNumber,
    }
  },
  components: {
    LoadingData,
  },
  methods: {
    closeModal() {
      hideModal('modalViewDraft')
      this.$emit('closeModal', false)
    },
    async getDraftInitial() {
      try {
        this.draft = await this.$store.dispatch('getDraftByCompany', {
          companyCode: this.$route.params.code,
        })
        this.isLoadingDraftInitial = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    showModal('modalViewDraft')
    this.getDraftInitial()
  },
}
</script>
