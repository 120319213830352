<template>
  <div>
    <div class="table-responsive table-scrollable">
      <table
        class="table table-sm fs-sm table-hover table-striped table-bordered"
      >
        <thead>
          <tr>
            <th>Centro de Custo / Natureza</th>
            <th class="text-center">Janeiro</th>
            <th class="text-center">Fevereiro</th>
            <th class="text-center">Março</th>
            <th class="text-center">Abril</th>
            <th class="text-center">Maio</th>
            <th class="text-center">Junho</th>
            <th class="text-center">Julho</th>
            <th class="text-center">Agosto</th>
            <th class="text-center">Setembro</th>
            <th class="text-center">Outubro</th>
            <th class="text-center">Novembro</th>
            <th class="text-center">Dezembro</th>
            <th class="text-center">Médio</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataByMonths" :key="item.cliente">
            <td>{{ item.name }}</td>
            <td
              class="text-center"
              v-for="(month, index) in item.months"
              :key="index"
            >
              {{ formatToCurrencyBr(month.valor) }}
            </td>
            <td class="text-center">
              {{ formatToCurrencyBr(item.media.valor) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <div>
          <h6>Itens Cadastrados: {{ numberOfRegisters }}</h6>
        </div>
        <div>
          <button
            v-if="!disableEdit"
            class="btn btn-success"
            :disabled="numberOfRegisters <= 0"
            @click="finish"
          >
            Finalizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'ReportFixedCostManager',

  props: {
    form: {
      type: Object,
      required: true,
    },
    stepId: {
      type: Number,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
    registers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedItem: null,
      dataByMonths: [],
      formatToCurrencyBr,
      showDeleteModal: false,
    }
  },
  emits: ['updateForms'],
  computed: {
    disableEdit() {
      return this.form.STATUS_FORMULARIO_ETAPA === 1
    },

    numberOfRegisters() {
      return this.registers.length
    },
  },
  mounted() {
    this.convertToTable()
  },
  methods: {
    convertToTable() {
      // Set in format to table { clientName: name, months: { 01 : {volume : 0, price: 0, total: 0}, total: {volume : 0, price: 0, total: 0}} }
      this.dataByMonths = this.registers.reduce((acc, register) => {
        const client = `${register.CODCCUSTO} - ${register.CENTRODECUSTO} / ${register.CODTBORCAMENTO} - ${register.NATUREZA}`
        const month = register.MES
        const valor = Number(register.VALOR)

        if (!acc[client]) {
          acc[client] = {
            name: client,
            months: {},
          }
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= 12; i++) {
            acc[client].months[i] = {
              valor: 0,
            }
          }
        }

        if (!acc[client].months[month]) {
          acc[client].months[month] = {
            valor: 0,
          }
        }

        acc[client].months[month] = {
          valor,
        }

        if (!acc[client].media) {
          acc[client].media = {
            valor: 0,
          }
        }
        // Media do custo unitario
        // eslint-disable-next-line operator-assignment
        acc[client].media.valor = acc[client].media.valor + valor / 12

        return acc
      }, {})
    },
    deleteComprasRegister(item) {
      this.selectedItem = item
      this.showDeleteModal = true
    },
    closeModal(needReload) {
      this.showDeleteModal = false
      if (needReload) {
        this.$emit('updateForms')
      }
    },

    async finish() {
      try {
        this.disableButton = true
        const response = await this.$store.dispatch(
          'finishManagerRegistersForm',
          {
            form: {
              companyCode: this.companyCode,
              stepId: this.stepId,
              formId: this.form.IDFORMULARIO,
              formStepId: this.form.IDFORMETAPA,
            },
            companyCode: this.companyCode,
          },
        )
        this.$root.$refs.notification.success(response.message)
        this.$emit('updateForms')
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
}
</script>
