<template>
  <div>
    <h6>Realizando o download da planilha...</h6>
  </div>
</template>
<script>
import XLSX from 'xlsx'

export default {
  name: 'DownloadExcelFile',
  props: {
    fieldsMap: Object,
    name: String,
    data: Array,
    link: String,
  },
  data() {
    return {}
  },
  methods: {
    downloadLink() {
      window.open(this.link, '_blank')
      this.$emit('finish')
    },
    generateFile() {
      let json = []
      // Caso envie dados, monta o array com os dados, usando a estrutura do fieldsMap
      if (this.data) {
        json = this.data.map((item) => {
          const newItem = {}
          Object.keys(this.fieldsMap).forEach((key) => {
            // A chave do novo objeto é o valor de fieldsMap e o valor é o item correspondente ou uma string vazia
            newItem[this.fieldsMap[key]] = item[key] || ''
          })
          return newItem
        })
      } else {
        // Monta um Array com os campos do objeto
        json = Object.values(this.fieldsMap).reduce((acc, key) => {
          acc[key] = ''
          return acc
        }, {})
        json = [json]
      }
      const ws = XLSX.utils.json_to_sheet(json)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, `${this.name}.xlsx`)
      this.$emit('finish')
    },
  },

  mounted() {
    if (this.link && this.link !== '') {
      this.downloadLink()
    } else {
      this.generateFile()
    }
  },
}
</script>
