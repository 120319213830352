<template>
  <div>
    <ModalAttachment
      v-if="showModalAttachments"
      :companyCode="companyCode"
      :formStepId="formStepId"
      @closeModal="closeModal"
    ></ModalAttachment>
    <div class="card-body">
      <h5>Orçamento Custo Pessoal</h5>
    </div>
    <div class="table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Conta</th>
            <th>Centro de Custo</th>
            <th>Natureza</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="lc in laborCost" :key="lc.IDCUSTOTRABALHISTA">
            <td>{{ lc.CODCCUSTO }} - {{ lc.CENTRODECUSTO }}</td>
            <td>{{ lc.CODTBORCAMENTO }} - {{ lc.NATUREZA }}</td>
            <td>
              {{ formatToCurrencyBr(lc.CUSTOUNITARIO) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="avaliation-container" v-if="status == 0">
      <div class="d-flex justify-content-between">
        <div class="p-3">
          <button
            class="btn btn-secondary ms-2"
            @click="showModalAttachments = true"
          >
            <i class="ph ph-paperclip me-2"></i>
            Visualizar Anexos
          </button>
        </div>

        <div class="default-avaliation text-end p-3" v-if="!showReproveForm">
          <button class="btn btn-danger ms-2" @click="showReproveForm = true">
            Reprovar
          </button>
          <button class="btn btn-success ms-2" @click="approveLaboralCost()">
            Aprovar
          </button>
        </div>
        <div class="reprovation-avaliation" v-if="showReproveForm">
          <div class="p-3">
            <p>Você pode marcar os apontamentos para atenção do responsável.</p>
            <textarea
              class="form-control"
              rows="3"
              placeholder="Justificativa"
              v-model="reproveJustification"
            ></textarea>
            <div class="text-end mt-2">
              <button
                class="btn btn-secondary ms-2"
                @click="showReproveForm = false"
              >
                Cancelar
              </button>
              <button class="btn btn-danger ms-2" @click="reproveLaboralCost()">
                Reprovar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.legend {
  width: 20px !important;
  height: 20px !important;
}
</style>
<script>
import { formatToCurrencyBr, formatToNumber } from '@/utils/currency'
import ModalAttachment from '@/components/forms/ModalAttachment.vue'

export default {
  name: 'LaborCostControllershipEvaluation',
  props: {
    laborCost: {
      type: Array,
      required: true,
    },
    status: {
      type: Number,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
    formStepId: {
      type: Number,
      required: true,
    },
  },
  components: { ModalAttachment },
  data() {
    return {
      formatToCurrencyBr,
      formatToNumber,
      formatedData: [],
      needsReview: [],
      showReproveForm: false,
      reproveJustification: '',
      showModalAttachments: false,
    }
  },
  emits: ['avaliateLaborCost'],

  methods: {
    closeModal() {
      this.showModalAttachments = false
    },
    approveLaboralCost() {
      this.$emit('avaliateLaborCost', {
        status: true,
      })
    },
    reproveLaboralCost() {
      if (this.reproveJustification === '') {
        this.$root.$refs.notification.warning('Justificativa é obrigatória.')
        return false
      }
      this.$emit('avaliateLaborCost', {
        needsReview: this.needsReview,
        status: false,
        justification: this.reproveJustification,
      })
    },
    // Remove all characters specials, as spaces, dots, commas, etc
    removeCharacterSpecials(value) {
      return value.replace(/[^0-9]/g, '')
    },
  },
  computed: {},
  mounted() {},
}
</script>
