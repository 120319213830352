<template>
  <div class="card">
    <div class="card-header d-flex align-items-center p-1">
      <h4 class="mb-0 ms-2 text-secondary">Avaliação Controladoria</h4>
      <div class="ms-auto"></div>
    </div>
    <LoadingData v-if="isLoadingAvaliation" />
    <div v-else>
      <ul class="nav nav-tabs nav-tabs-underline nav-justified" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            href="#tab-form-controllership-evaluation-global"
            class="nav-link active"
            data-bs-toggle="tab"
            aria-selected="true"
            role="tab"
          >
            <div class="d-flex align-items-center">
              <i
                class="ph-globe ph-lg bg-primary text-primary bg-opacity-10 rounded p-2 me-3"
              ></i>
              <div>
                <div class="fw-semibold">Visão Geral</div>
                <span class="opacity-50"
                  >Visão geral do campos preenchidos</span
                >
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a
            href="#tab-form-commercial"
            class="nav-link"
            data-bs-toggle="tab"
            role="tab"
          >
            <div class="d-flex align-items-center">
              <i
                :class="
                  statusClassControllershipEvaluation(
                    avaliation.STATUSCOMERCIAL,
                  )
                "
                class="ph-tag ph-lg bg-primary text-primary bg-opacity-10 rounded p-2 me-3"
              ></i>
              <div>
                <div class="fw-semibold">Comercial</div>
                <span class="opacity-50">Avaliação do vendas</span>
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a
            href="#tab-form-controllership-evaluation-purchasing"
            class="nav-link"
            data-bs-toggle="tab"
            role="tab"
          >
            <div class="d-flex align-items-center">
              <i
                :class="
                  statusClassControllershipEvaluation(avaliation.STATUSCOMPRAS)
                "
                class="ph-shopping-cart-simple ph-lg bg-opacity-10 rounded p-2 me-3"
              ></i>
              <div>
                <div class="fw-semibold">Compras</div>
                <span class="opacity-50">Avaliação custo dos itens</span>
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a
            href="#tab-form-controllership-evaluation-labor-cost"
            class="nav-link"
            data-bs-toggle="tab"
            role="tab"
          >
            <div class="d-flex align-items-center">
              <i
                :class="
                  statusClassControllershipEvaluation(
                    avaliation.STATUSCUSTOTRABALISTA,
                  )
                "
                class="ph-users-three ph-lg bg-opacity-10 rounded p-2 me-3"
              ></i>
              <div>
                <div class="fw-semibold">DP</div>
                <span class="opacity-50">Avaliação de custos trabalhistas</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div
          class="tab-pane active fade show"
          id="tab-form-controllership-evaluation-global"
          role="tabpanel"
        >
          <SummaryControllershipEvaluation
            v-if="!isLoadingDraft && !isLoadingCommercial"
            :draft="draft"
            :commercial="commercial"
          />
        </div>
        <div class="tab-pane fade" id="tab-form-commercial" role="tabpanel">
          <CommercialControllershipEvaluation
            v-if="!isLoadingDraft && !isLoadingCommercial"
            :draft="draft"
            :commercial="commercial"
            :status="avaliation.STATUSCOMERCIAL"
            :formStepId="commercialFormStepId"
            :companyCode="companyCode"
            @avaliateCommercial="avaliateCommercial"
          />
        </div>
        <div
          class="tab-pane fade"
          id="tab-form-controllership-evaluation-purchasing"
          role="tabpanel"
        >
          <PurchasingControllershipEvaluation
            v-if="!isLoadingDraft && !isLoadingPurchasing"
            :status="avaliation.STATUSCOMPRAS"
            :purchasing="purchasing"
            :formStepId="purchasingFromStepId"
            :companyCode="companyCode"
            @avaliatePurchasing="avaliatePurchasing"
          />
        </div>
        <div
          class="tab-pane fade"
          id="tab-form-controllership-evaluation-labor-cost"
          role="tabpanel"
        >
          <LaborCostControllershipEvaluation
            v-if="!isLoadingDraft && !isLoadingLaborCost"
            :status="avaliation.STATUSCUSTOTRABALISTA"
            :laborCost="laborCost"
            :formStepId="laborCostFormStepId"
            :companyCode="companyCode"
            @avaliateLaborCost="avaliateLaborCost"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatToCurrencyBr } from '@/utils/currency'
import { statusClassControllershipEvaluation } from '@/utils/strings'
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import SummaryControllershipEvaluation from '@/components/forms/controllershipEvaluation/SummaryControllershipEvaluation.vue'
import CommercialControllershipEvaluation from '@/components/forms/controllershipEvaluation/CommercialControllershipEvaluation.vue'
import PurchasingControllershipEvaluation from '@/components/forms/controllershipEvaluation/PurchasingControllershipEvaluation.vue'
import LaborCostControllershipEvaluation from '@/components/forms/controllershipEvaluation/LaborCostControllershipEvaluation.vue'

export default {
  name: 'FormControllershipEvaluation',
  props: {
    stepId: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
  },
  components: {
    LoadingData,
    SummaryControllershipEvaluation,
    CommercialControllershipEvaluation,
    PurchasingControllershipEvaluation,
    LaborCostControllershipEvaluation,
  },
  data() {
    return {
      statusClassControllershipEvaluation,
      formatToCurrencyBr,
      // Dados
      isLoadingAvaliation: true,
      avaliation: {},
      draft: {},
      isLoadingDraft: false,
      commercial: [],
      isLoadingCommercial: false,
      commercialFormStepId: null,
      purchasing: [],
      isLoadingPurchasing: false,
      purchasingFromStepId: null,
      laborCost: [],
      isLoadingLaborCost: false,
      laborCostFormStepId: null,
    }
  },
  emits: ['updateForms'],

  methods: {
    async getLastedControllershipEvaluation() {
      try {
        this.isLoadingAvaliation = true
        this.avaliation = await this.$store.dispatch(
          'getLastedControllershipEvaluation',
          {
            companyCode: this.companyCode,
            stepId: this.stepId,
            formId: this.form.IDFORMULARIO,
          },
        )
        this.isLoadingAvaliation = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getDraft() {
      try {
        this.isLoadingDraft = true
        this.draft = await this.$store.dispatch('getDraftByCompany', {
          companyCode: this.companyCode,
        })
        this.isLoadingDraft = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getPurchasingBudget() {
      try {
        this.isLoadingPurchasing = true
        this.purchasing = await this.$store.dispatch(
          'getPurchasingBudget',
          this.companyCode,
        )
        this.purchasingFromStepId = this.purchasing[0].IDFORMULARIOETAPA
        this.isLoadingPurchasing = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getCommercialBudget() {
      try {
        this.isLoadingCommercial = true
        this.commercial = await this.$store.dispatch(
          'getCommercialBudget',
          this.companyCode,
        )
        this.commercialFormStepId = this.commercial[0].IDFORMULARIOETAPA
        this.isLoadingCommercial = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getLaborCostBudget() {
      try {
        this.isLoadingLaborCost = true
        this.laborCost = await this.$store.dispatch(
          'getLaborCostBudget',
          this.companyCode,
        )
        this.laborCostFormStepId = this.laborCost[0].IDFORMULARIOETAPA
        this.isLoadingLaborCost = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async avaliateCommercial(data) {
      try {
        const result = await this.$store.dispatch(
          'avaliateControllershipEvaluationCommercial',
          {
            companyCode: this.companyCode,
            data: {
              status: data.status,
              justification: data.justification,
              needsReview: data.needsReview,
              stepId: this.stepId,
              formId: this.form.IDFORMULARIO,
            },
          },
        )
        this.getCommercialBudget()
        this.getLastedControllershipEvaluation()
        this.$root.$refs.notification.success(result.message)
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async avaliatePurchasing(data) {
      try {
        const result = await this.$store.dispatch(
          'avaliateControllershipEvaluationPurchasing',
          {
            companyCode: this.companyCode,
            data: {
              status: data.status,
              justification: data.justification,
              needsReview: data.needsReview,
              stepId: this.stepId,
              formId: this.form.IDFORMULARIO,
            },
          },
        )
        this.getLastedControllershipEvaluation()
        this.getPurchasingBudget()
        this.$root.$refs.notification.success(result.message)
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async avaliateLaborCost(data) {
      try {
        const result = await this.$store.dispatch(
          'avaliateControllershipEvaluationLaborCost',
          {
            companyCode: this.companyCode,
            data: {
              status: data.status,
              justification: data.justification,
              needsReview: data.needsReview,
              stepId: this.stepId,
              formId: this.form.IDFORMULARIO,
            },
          },
        )
        this.getLastedControllershipEvaluation()
        this.getLaborCostBudget()
        this.$root.$refs.notification.success(result.message)
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getLastedControllershipEvaluation()
    this.getDraft()
    this.getCommercialBudget()
    this.getPurchasingBudget()
    this.getLaborCostBudget()
  },
}
</script>
