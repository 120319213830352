<template>
  <div id="modalUpladFile" class="modal fade" tabindex="-1">
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Selecione a Planilha</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="sendData">
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label">
                  Arquivo em Excel (XLSX):
                  <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                  <input
                    type="file"
                    ref="xlsxFile"
                    class="form-control"
                    multiple="false"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    @change="addFile"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-responsive table-content">
                <div v-html="htmlTable"></div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 pt-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Confirmar"
              :disabled="disableButton"
            >
              <span>Enviar Dados</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style>
.table-content {
  max-height: 300px;
  overflow-y: auto;
}
</style>
<script>
import { mapGetters } from 'vuex'
import { showModal, hideModal } from '@/utils/modal'
import XLSX from 'xlsx'

export default {
  name: 'UploadExcelFile',
  data() {
    return {
      modalSize: 'modal-sm',
      file: null,
      fileContent: null,
      disableButton: true,
      htmlTable: '',
      worksheet: [],
    }
  },
  computed: {
    ...mapGetters(['companies']),
  },
  methods: {
    formatTable(worksheet) {
      const htmlTable = `<table class="table table-bordered table-striped table-hover">
        <thead>
          <tr>
            ${Object.keys(worksheet[0])
              .map((key) => `<th>${key}</th>`)
              .join('')}
          </tr>
        </thead>
        <tbody>
          ${worksheet
            .map((row) => {
              return `<tr>${Object.values(row)
                .map((value) => `<td>${value}</td>`)
                .join('')}</tr>`
            })
            .join('')}
        </tbody>
      </table>`
      this.modalSize = 'modal-full'
      this.htmlTable = htmlTable
    },
    async addFile(event) {
      const { files } = event.target
      const file = files[0]
      const data = await file.arrayBuffer()
      /* data is an ArrayBuffer */
      const workbook = XLSX.read(data)
      /* do something with the workbook here */
      const worksheet = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
      )
      this.worksheet = worksheet
      this.disableButton = false
      this.formatTable(worksheet)
      // this.htmlTable = setHTML(this.htmlTable)
    },
    sendData() {
      this.$emit('importData', this.worksheet)
      hideModal('modalUpladFile')
    },
  },

  mounted() {
    showModal('modalUpladFile')
  },
}
</script>
