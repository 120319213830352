<template>
  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <th>Item</th>
        <th>Draft</th>
        <th>Orçado</th>
        <th>Diferença</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Volume</td>
        <td>{{ formatToNumber(draft.volume) }}</td>
        <td>{{ formatToNumber(commercialTotals.volume) }}</td>
        <td>{{ formatToNumber(difference.volume) }}</td>
      </tr>
      <tr>
        <td>Receita Bruta</td>
        <td>{{ formatToCurrencyBr(draft.receitaBruta) }}</td>
        <td>{{ formatToCurrencyBr(commercialTotals.receitaBruta) }}</td>
        <td>{{ formatToCurrencyBr(difference.receitaBruta) }}</td>
      </tr>
      <tr>
        <td>Deduções</td>
        <td>{{ formatToCurrencyBr(draft.deducoes) }}</td>
        <td>{{ formatToCurrencyBr(commercialTotals.deducoes) }}</td>
        <td>{{ formatToCurrencyBr(difference.deducoes) }}</td>
      </tr>
      <tr>
        <td>Receita Líquida</td>
        <td>{{ formatToCurrencyBr(draft.receitaLiquida) }}</td>
        <td>{{ formatToCurrencyBr(commercialTotals.receitaLiquida) }}</td>
        <td>{{ formatToCurrencyBr(difference.receitaLiquida) }}</td>
      </tr>
      <tr>
        <td>Custos Variáveis</td>
        <td>{{ formatToCurrencyBr(draft.custosVariaveis) }}</td>
        <td>{{ formatToCurrencyBr(commercialTotals.custosVariaveis) }}</td>
        <td>{{ formatToCurrencyBr(difference.custosVariaveis) }}</td>
      </tr>
      <tr>
        <td>Margem de Contribuição</td>
        <td>{{ formatToCurrencyBr(draft.margemContribuicao) }}</td>
        <td>{{ formatToCurrencyBr(commercialTotals.margemContribuicao) }}</td>
        <td>{{ formatToCurrencyBr(difference.margemContribuicao) }}</td>
      </tr>
      <tr>
        <td>Custos Fixos</td>
        <td>{{ formatToCurrencyBr(draft.custosFixos) }}</td>
        <td>{{ formatToCurrencyBr(commercialTotals.custosFixos) }}</td>
        <td>{{ formatToCurrencyBr(difference.custosFixos) }}</td>
      </tr>
      <tr>
        <td>EBITDA</td>
        <td>{{ formatToCurrencyBr(draft.ebitda) }}</td>
        <td>{{ formatToCurrencyBr(commercialTotals.ebitda) }}</td>
        <td>{{ formatToCurrencyBr(difference.ebitda) }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { formatToCurrencyBr, formatToNumber } from '@/utils/currency'

export default {
  name: 'SummaryControllershipEvaluation',
  props: {
    draft: {
      type: Object,
      required: true,
    },
    commercial: {
      type: Array,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      formatToCurrencyBr,
      formatToNumber,
      // Dados
      commercialTotals: {
        volume: 0,
        receitaBruta: 0,
        deducoes: 0,
        receitaLiquida: 0,
        custosVariaveis: 0,
        margemContribuicao: 0,
        custosFixos: 0,
        ebitda: 0,
      },
      difference: {
        volume: 0,
        receitaBruta: 0,
        deducoes: 0,
        receitaLiquida: 0,
        custosVariaveis: 0,
        margemContribuicao: 0,
        custosFixos: 0,
        ebitda: 0,
      },
    }
  },
  emits: ['updateForms'],

  methods: {
    sumCommercial() {
      this.commercialTotals = this.commercial.reduce((acc, item) => {
        acc.volume += Number(item.VOLUME)
        acc.receitaBruta += Number(item.VALORTOTAL)
        acc.deducoes += 0
        return acc
      }, this.commercialTotals)
      this.commercialTotals.receitaLiquida =
        this.commercialTotals.receitaBruta - this.commercialTotals.deducoes
      this.commercialTotals.custosVariaveis = 0
      this.commercialTotals.margemContribuicao =
        this.commercialTotals.receitaLiquida -
        this.commercialTotals.custosVariaveis
      this.commercialTotals.custosFixos = 0
      this.commercialTotals.ebitda =
        this.commercialTotals.margemContribuicao -
        this.commercialTotals.custosFixos
      this.calculateDifference()
    },
    calculateDifference() {
      this.difference.volume = this.draft.volume - this.commercialTotals.volume
      this.difference.receitaBruta =
        this.draft.receitaBruta - this.commercialTotals.receitaBruta
      this.difference.deducoes =
        this.draft.deducoes - this.commercialTotals.deducoes
      this.difference.receitaLiquida =
        this.draft.receitaLiquida - this.commercialTotals.receitaLiquida
      this.difference.custosVariaveis =
        this.draft.custosVariaveis - this.commercialTotals.custosVariaveis
      this.difference.margemContribuicao =
        this.draft.margemContribuicao - this.commercialTotals.margemContribuicao
      this.difference.custosFixos =
        this.draft.custosFixos - this.commercialTotals.custosFixos
      this.difference.ebitda = this.draft.ebitda - this.commercialTotals.ebitda
    },
  },

  mounted() {
    setTimeout(() => {
      this.sumCommercial()
    }, 1000)
  },
}
</script>
