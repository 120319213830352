<template>
  <div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="col">
            <div class="d-flex flex-column">
              <span class="d-flex">
                <span class="bg-success rounded-pill me-2 legend"></span
                >Volume</span
              >
              <span class="d-flex">
                <span class="bg-indigo rounded-pill me-2 legend"></span>Preço
                Médio</span
              >
              <span class="d-flex">
                <span class="bg-pink rounded-pill me-2 legend"></span
                >Total</span
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="col">
            <div class="d-flex flex-column">
              <p class="mb-0">
                <strong>Volume Total Orçado:</strong>
                {{ formatToNumber(totalVolume) }}
              </p>
              <p class="mb-0">
                <strong>Valor Total Orçado:</strong>
                {{ formatToCurrencyBr(totalValue) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-striped table-xs fs-sm table-bordered">
        <thead>
          <tr>
            <th class="column-name">Cliente</th>
            <th
              class="column-month text-center"
              v-for="month in months"
              :key="month"
            >
              {{ month.name }}
            </th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="client in formatedData" :key="client.name">
            <td>{{ client.name }}</td>
            <td
              v-for="month in 12"
              :key="month"
              class="text-center p-1"
              :class="client.months[month].idComercial ? '' : 'opacity-50'"
            >
              <div class="">
                <div class="text-success fw-bold">
                  {{ formatToNumber(client.months[month].volume) }}
                </div>
                <div class="text-indigo fw-bold">
                  {{ formatToCurrencyBr(client.months[month].price) }}
                </div>
                <div class="text-pink fw-bold">
                  {{ formatToCurrencyBr(client.months[month].total) }}
                </div>
              </div>
            </td>
            <td class="p-1 text-center">
              <div class="text-success fw-bold">
                {{ formatToNumber(client.total.volume) }}
              </div>
              <div class="text-indigo fw-bold">
                {{ formatToCurrencyBr(client.total.price) }}
              </div>
              <div class="text-pink fw-bold">
                {{ formatToCurrencyBr(client.total.total) }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-success"
          @click="finish"
          :disabled="disableButton || commercial.length === 0"
        >
          <i class="ph-check me-2"></i>
          Finalizar
        </button>
      </div>
    </div>
  </div>
</template>
<style>
.legend {
  width: 20px !important;
  height: 20px !important;
}
.column-name {
  min-width: 300px;
}
.column-month {
  min-width: 140px;
}
</style>
<script>
import { formatToCurrencyBr, formatToNumber } from '@/utils/currency'

export default {
  name: 'ReportCommercial',
  props: {
    commercial: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    stepId: {
      type: Number,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formatToCurrencyBr,
      formatToNumber,
      formatedData: [],
      disableButton: false,
      months: [
        { id: 1, name: 'Janeiro' },
        { id: 2, name: 'Fevereiro' },
        { id: 3, name: 'Março' },
        { id: 4, name: 'Abril' },
        { id: 5, name: 'Maio' },
        { id: 6, name: 'Junho' },
        { id: 7, name: 'Julho' },
        { id: 8, name: 'Agosto' },
        { id: 9, name: 'Setembro' },
        { id: 10, name: 'Outubro' },
        { id: 11, name: 'Novembro' },
        { id: 12, name: 'Dezembro' },
      ],
    }
  },
  emits: ['avaliateCommercial'],
  computed: {
    disableEdit() {
      return this.form.STATUS_FORMULARIO_ETAPA === 1
    },

    totalVolume() {
      return this.commercial.reduce(
        (acc, register) => acc + Number(register.VOLUME),
        0,
      )
    },
    totalValue() {
      return this.commercial.reduce(
        (acc, register) => acc + Number(register.VALORTOTAL),
        0,
      )
    },
  },
  methods: {
    // Remove all characters specials, as spaces, dots, commas, etc
    removeCharacterSpecials(value) {
      return value.replace(/[^0-9]/g, '')
    },
    convertToTable() {
      this.formatedData = this.commercial.reduce((acc, register) => {
        const client = register.NOME
        const month = register.MES
        const volume = Number(register.VOLUME)
        const price = Number(register.PRECOMEDIO)
        const total = Number(register.VALORTOTAL)
        const idComercial = register.IDCOMERCIAL

        if (!acc[client]) {
          acc[client] = {
            name: client,
            months: {},
          }
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= 12; i++) {
            acc[client].months[i] = {
              id: null,
              volume: 0,
              price: 0,
              total: 0,
            }
          }
        }

        if (!acc[client].months[month]) {
          acc[client].months[month] = {
            id: idComercial,
            volume: 0,
            price: 0,
            total: 0,
          }
        }

        acc[client].months[month] = {
          idComercial,
          volume,
          price,
          total,
        }

        if (!acc[client].total) {
          acc[client].total = {
            volume: 0,
            price: 0,
            total: 0,
          }
        }

        acc[client].total.volume += volume
        acc[client].total.price += price
        acc[client].total.total += total

        return acc
      }, {})
    },
    async finish() {
      try {
        this.disableButton = true
        const response = await this.$store.dispatch('finishCommercialForm', {
          form: {
            companyCode: this.companyCode,
            stepId: this.stepId,
            formId: this.form.IDFORMULARIO,
            formStepId: this.form.IDFORMETAPA,
          },
          companyCode: this.companyCode,
        })
        this.$root.$refs.notification.success(response.message)
        this.$emit('updateForms', true)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.convertToTable()
    console.log(this.formatedData)
  },
}
</script>
