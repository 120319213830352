<template>
  <div>
    <ModalAttachment
      v-if="showModalAttachments"
      :companyCode="companyCode"
      :formStepId="formStepId"
      @closeModal="closeModal"
    ></ModalAttachment>
    <div class="card-body">
      <h5>Orçamento Compras</h5>
    </div>
    <div class="table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Cliente</th>
            <th v-for="month in 12" :key="month">{{ month }}</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="client in formatedData" :key="client.name">
            <td>{{ client.name }}</td>
            <td v-for="month in 12" :key="month">
              <div
                class="d-flex align-items-center"
                :class="client.months[month].idPurchasing ? '' : 'opacity-50'"
              >
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    :id="`nr_${removeCharacterSpecials(client.name)}_${month}`"
                    v-model="needsReview"
                    :value="client.months[month].idPurchasing"
                    :disabled="!client.months[month].idPurchasing"
                  />
                  <label
                    class="form-check-label"
                    :for="`nr_${removeCharacterSpecials(client.name)}_${month}`"
                  ></label>
                </div>
                <div v-if="client.months[month]">
                  <div class="text-indigo fw-bold">
                    {{ formatToCurrencyBr(client.months[month].price) }}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="text-indigo fw-bold">
                {{ formatToCurrencyBr(client.avg.price) }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="avaliation-container" v-if="status == 0">
      <div class="d-flex justify-content-between">
        <div class="p-3">
          <button
            class="btn btn-secondary ms-2"
            @click="showModalAttachments = true"
          >
            <i class="ph ph-paperclip me-2"></i>
            Visualizar Anexos
          </button>
        </div>
        <div>
          <div class="default-avaliation text-end p-3" v-if="!showReproveForm">
            <button class="btn btn-danger ms-2" @click="showReproveForm = true">
              Reprovar
            </button>
            <button class="btn btn-success ms-2" @click="approvePurchasing()">
              Aprovar
            </button>
          </div>
          <div class="reprovation-avaliation" v-if="showReproveForm">
            <div class="p-3">
              <p>
                Você pode marcar os apontamentos para atenção do responsável.
              </p>
              <textarea
                class="form-control"
                rows="3"
                placeholder="Justificativa"
                v-model="reproveJustification"
              ></textarea>
              <div class="text-end mt-2">
                <button
                  class="btn btn-secondary ms-2"
                  @click="showReproveForm = false"
                >
                  Cancelar
                </button>
                <button
                  class="btn btn-danger ms-2"
                  @click="reprovePurchasing()"
                >
                  Reprovar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.legend {
  width: 20px !important;
  height: 20px !important;
}
</style>
<script>
import { formatToCurrencyBr, formatToNumber } from '@/utils/currency'
import ModalAttachment from '@/components/forms/ModalAttachment.vue'

export default {
  name: 'PurchasingControllershipEvaluation',
  props: {
    status: {
      type: Number,
      required: true,
    },
    purchasing: {
      type: Array,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
    formStepId: {
      type: Number,
      required: true,
    },
  },
  components: {
    ModalAttachment,
  },
  data() {
    return {
      formatToCurrencyBr,
      formatToNumber,
      formatedData: [],
      needsReview: [],
      showReproveForm: false,
      reproveJustification: '',
      showModalAttachments: false,
    }
  },
  emits: ['avaliatePurchasing'],

  methods: {
    closeModal() {
      this.showModalAttachments = false
    },
    approvePurchasing() {
      this.$emit('avaliatePurchasing', {
        status: true,
      })
    },
    reprovePurchasing() {
      if (this.reproveJustification === '') {
        this.$root.$refs.notification.warning('Justificativa é obrigatória.')
        return false
      }
      this.$emit('avaliatePurchasing', {
        needsReview: this.needsReview,
        status: false,
        justification: this.reproveJustification,
      })
    },
    // Remove all characters specials, as spaces, dots, commas, etc
    removeCharacterSpecials(value) {
      return value.replace(/[^0-9]/g, '')
    },
    convertToTable() {
      this.formatedData = this.purchasing.reduce((acc, register) => {
        const client = `${register.CODCCUSTO} - ${register.CENTRODECUSTO} / ${register.CODTBORCAMENTO} - ${register.NATUREZA}`

        const month = register.MES
        const price = Number(register.CUSTOUNITARIO)
        const idPurchasing = register.IDCOMPRAS

        if (!acc[client]) {
          acc[client] = {
            name: client,
            months: {},
          }
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= 12; i++) {
            acc[client].months[i] = {
              id: null,
              price: 0,
            }
          }
        }

        if (!acc[client].months[month]) {
          acc[client].months[month] = {
            id: idPurchasing,
            price: 0,
          }
        }

        acc[client].months[month] = {
          idPurchasing,
          price,
        }

        if (!acc[client].avg) {
          acc[client].avg = {
            price: 0,
          }
        }

        acc[client].avg.price += price

        return acc
      }, {})
    },
  },
  mounted() {
    this.convertToTable()
  },
}
</script>
