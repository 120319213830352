let modal = null

const showModal = (id) => {
  modal = new window.bootstrap.Modal(document.getElementById(id), {
    backdrop: 'static',
    keyboard: false,
  })
  modal.show()
}

const hideModal = () => {
  modal.hide()
}

export { showModal, hideModal }
