<template>
  <div class="card">
    <div class="card-header p-1">
      <h4 class="mb-0 ms-2 text-primary text-center">Custo Pessoal</h4>
      <p class="opacity-50 text-center">
        {{
          disableEdit ? 'Preenchimento Finalizado' : 'Aguardando Preenchimento'
        }}
      </p>
    </div>
    <ul class="nav nav-tabs nav-tabs-highlight nav-justified" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          href="#tab-form-manager-collaborators"
          class="nav-link active"
          data-bs-toggle="tab"
          aria-selected="true"
          role="tab"
        >
          <div class="d-flex align-items-center">
            <i
              class="ph-sort-ascending ph-lg bg-primary text-white rounded p-2 me-3"
            ></i>
            <div>
              <div class="fw-semibold">Gerenciar</div>
              <span class="opacity-50"
                >Promoção e Demissão de Colaboradores</span
              >
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          href="#tab-form-add-collaborators"
          class="nav-link"
          data-bs-toggle="tab"
          role="tab"
        >
          <div class="d-flex">
            <i
              class="ph-user-plus ph-sort-ascending ph-lg bg-primary text-white rounded p-2 me-3"
            ></i>
            <div>
              <div class="fw-semibold">Novo Colaborador</div>
              <span class="opacity-50">Cadastrar Colaborador</span>
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          href="#tab-form-report-collaborators"
          class="nav-link"
          data-bs-toggle="tab"
          role="tab"
        >
          <div class="d-flex">
            <i
              class="ph-calculator ph-lg bg-primary text-white rounded p-2 me-3"
            ></i>
            <div>
              <div class="fw-semibold">Relatório</div>
              <span class="opacity-50">Compilado dos dados informados</span>
            </div>
          </div>
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane active fade show"
        id="tab-form-manager-collaborators"
        role="tabpanel"
      >
        <LoadingData
          v-if="isLoadingColaborators"
          class="d-flex justify-content-center align-items-center"
        ></LoadingData>
        <div v-else>
          <div v-if="hasError == false">
            <PromotePersonalCostManager
              v-if="showPromoteModal"
              :collaborator="selectedCollaborator"
              :stepId="stepId"
              :months="months"
              :form="form"
              @closeModal="closeModal"
            />
            <RemovePersonalCostManager
              v-if="showRemoveModal"
              :collaborator="selectedCollaborator"
              :stepId="stepId"
              :months="months"
              :form="form"
              @closeModal="closeModal"
            />
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Chapa</th>
                    <th>Nome</th>
                    <th>Cargo</th>
                    <th>Operação</th>
                    <th>Mês</th>
                    <th>Salário</th>
                    <th>Novo Salário</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(collaborator, index) in collaborators"
                    :key="index"
                  >
                    <td>{{ collaborator.CHAPA }}</td>
                    <td>{{ collaborator.NOME }}</td>
                    <td>{{ collaborator.CARGO }}</td>
                    <td>{{ collaborator.REGISTRO.OPERACAO }}</td>
                    <td>
                      {{
                        collaborator.REGISTRO.MES > 0
                          ? collaborator.REGISTRO.MES
                          : '-'
                      }}
                    </td>
                    <td>
                      {{ formatToCurrencyBr(Number(collaborator.SALARIO)) }}
                    </td>
                    <td>
                      {{
                        formatToCurrencyBr(
                          Number(collaborator.REGISTRO.NOVOSALARIO),
                        )
                      }}
                    </td>
                    <td>
                      <span
                        class="badge bg-success me-1 cursor-pointer"
                        @click="promoteCollaborator(collaborator)"
                        >Promover</span
                      >
                      <span
                        class="badge bg-danger me-1 cursor-pointer"
                        @click="removeCollaborator(collaborator)"
                        >Desligar</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>
            <h5 class="text-center">
              {{ hasError }}
            </h5>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="tab-form-add-collaborators"
        role="tabpanel"
      >
        <FormNewCollaborador
          :stepId="stepId"
          :form="form"
          :companyCode="companyCode"
          @reloadData="reloadData"
        />
      </div>
      <div
        class="tab-pane fade"
        id="tab-form-report-collaborators"
        role="tabpanel"
      >
        <LoadingData
          v-if="isLoadingReports"
          class="d-flex justify-content-center align-items-center"
        />
        <ReportCollaborador
          v-else
          :report="report"
          :form="form"
          :companyCode="companyCode"
          @reloadData="reloadData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import PromotePersonalCostManager from '@/components/forms/personalCostManager/PromotePersonalCostManager.vue'
import RemovePersonalCostManager from '@/components/forms/personalCostManager/RemovePersonalCostManager.vue'
import FormNewCollaborador from '@/components/forms/personalCostManager/FormNewCollaborador.vue'
import ReportCollaborador from '@/components/forms/personalCostManager/ReportCollaborador.vue'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'FormPersonalCostManager',
  props: {
    stepId: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
  },
  components: {
    LoadingData,
    PromotePersonalCostManager,
    RemovePersonalCostManager,
    FormNewCollaborador,
    ReportCollaborador,
  },
  data() {
    return {
      pageTitle: 'Formulário Custos Pessoais',
      formatToCurrencyBr,
      // Loading
      isLoadingColaborators: true,
      isLoadingReports: true,
      showPromoteModal: false,
      showRemoveModal: false,
      collaborators: [],
      report: null,
      hasError: false,
      selectedCollaborator: null,
      months: [
        { value: '01', name: 'Janeiro' },
        { value: '02', name: 'Fevereiro' },
        { value: '03', name: 'Março' },
        { value: '04', name: 'Abril' },
        { value: '05', name: 'Maio' },
        { value: '06', name: 'Junho' },
        { value: '07', name: 'Julho' },
        { value: '08', name: 'Agosto' },
        { value: '09', name: 'Setembro' },
        { value: '10', name: 'Outubro' },
        { value: '11', name: 'Novembro' },
        { value: '12', name: 'Dezembro' },
      ],
    }
  },
  emits: ['updateForms'],
  computed: {
    disableEdit() {
      return this.form.STATUS_FORMULARIO_ETAPA === 1
    },
  },

  methods: {
    reloadData() {
      this.getCollaboratorsWithRegister()
      this.getPersonalCostsReport()
    },
    promoteCollaborator(collaborator) {
      this.showPromoteModal = true
      this.selectedCollaborator = collaborator
    },
    removeCollaborator(collaborator) {
      this.showRemoveModal = true
      this.selectedCollaborator = collaborator
    },
    closeModal(needReload) {
      this.showPromoteModal = false
      this.showRemoveModal = false
      if (needReload) {
        this.getCollaboratorsWithRegister()
      }
    },
    finishProcess() {
      this.$emit('updateForms')
    },
    async getCollaboratorsWithRegister() {
      try {
        this.isLoadingColaborators = true
        this.collaborators = await this.$store.dispatch(
          'getCollaboratorsWithRegister',
          {
            companyCode: this.companyCode,
            stepId: this.stepId,
            formId: this.form.IDFORMULARIO,
            formStepId: this.form.IDFORMETAPA,
          },
        )
        this.isLoadingColaborators = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getPersonalCostsReport() {
      try {
        this.isLoadingReports = true
        this.report = await this.$store.dispatch('getPersonalCostsReport', {
          companyCode: this.companyCode,
          stepId: this.stepId,
          formId: this.form.IDFORMULARIO,
          formStepId: this.form.IDFORMETAPA,
        })
        this.isLoadingReports = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
          this.hasError = error.message
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.reloadData()
  },
}
</script>
