<template>
  <div>
    <ModalDeleteLaborCost
      v-if="showDeleteModal"
      :selectedItem="selectedItem"
      @closeModal="closeModal"
    />
    <div class="table-responsive table-scrollable">
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th class="text-center">Versão</th>
            <th>Centro de Custo</th>
            <th>Natureza</th>
            <th class="text-center">Valor Por Colaborador</th>
            <th>Competência</th>
            <th class="text-center" v-if="!disableEdit">Excluir</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in registers" :key="key">
            <td class="text-center">
              <span
                v-if="item.REVISAR"
                class="bg-yellow bg-opacity-10 text-warning lh-1 rounded-pill p-2"
              >
                <i class="ph-warning"></i>
              </span>
              <span
                v-else
                class="bg-primary bg-opacity-10 text-primary lh-1 rounded-pill p-2"
              >
                <span class="letter-icon"> {{ item.VERSAO }}</span>
              </span>
            </td>
            <td>{{ item.CODCCUSTO }} - {{ item.CENTRODECUSTO }}</td>
            <td>{{ item.CODTBORCAMENTO }} - {{ item.NATUREZA }}</td>
            <td class="text-center">
              {{ formatToCurrencyBr(item.CUSTOUNITARIO) }}
            </td>
            <td>{{ item.MES > 0 ? item.MES : 'Anual' }}</td>
            <td class="text-center p-0" v-if="!disableEdit">
              <button
                class="btn btn-danger btn-sm"
                @click="deleteLaborCostRegister(item)"
              >
                <i class="ph ph-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <div>
          <h6>Itens Cadastrados: {{ numberOfRegisters }}</h6>
        </div>
        <div>
          <button
            v-if="!disableEdit"
            class="btn btn-success"
            :disabled="numberOfRegisters <= 0"
            @click="finish"
          >
            Finalizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalDeleteLaborCost from '@/components/forms/laborCost/ModalDeleteLaborCost.vue'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'RegisterLaborCost',
  props: {
    form: {
      type: Object,
      required: true,
    },
    stepId: {
      type: Number,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
    registers: {
      type: Array,
      required: true,
    },
  },
  components: {
    ModalDeleteLaborCost,
  },
  data() {
    return {
      selectedItem: null,
      dataByMonths: [],
      formatToCurrencyBr,
      showDeleteModal: false,
    }
  },
  emits: ['updateForms'],
  computed: {
    disableEdit() {
      return this.form.STATUS_FORMULARIO_ETAPA === 1
    },

    numberOfRegisters() {
      return this.registers.length
    },
  },
  methods: {
    deleteLaborCostRegister(item) {
      this.selectedItem = item
      this.showDeleteModal = true
    },
    closeModal(needReload) {
      this.showDeleteModal = false
      if (needReload) {
        this.$emit('updateForms', needReload)
      }
    },

    async finish() {
      try {
        this.disableButton = true
        const response = await this.$store.dispatch('finishLaborCostForm', {
          form: {
            companyCode: this.companyCode,
            stepId: this.stepId,
            formId: this.form.IDFORMULARIO,
            formStepId: this.form.IDFORMETAPA,
          },
          companyCode: this.companyCode,
        })
        this.$root.$refs.notification.success(response.message)
        this.$emit('updateForms', true)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
}
</script>
