<template>
  <div class="p-1">
    <div v-if="isLoadingSteps">
      <LoadingData />
    </div>
    <div v-else>
      <div v-if="notAvaliableStep">
        <div class="card card-body">
          <h6 class="mb-0">{{ notAvaliableStep }}</h6>
        </div>
      </div>
      <div v-else>
        <div v-if="avaliableSteps.length > 0">
          <div class="card card-body bg-info text-white">
            <h6 class="mb-0">
              <i class="ph ph-info"></i> Etapas em andamento:
              {{ avaliableStepsNames.join(', ') }}
            </h6>
          </div>
          <stepsAvaliables
            v-for="step in avaliableSteps"
            :key="step.NUMETAPA"
            :step="step"
            @getAvaliableStepsOnFlow="getAvaliableStepsOnFlow"
          />
        </div>
        <div v-else>
          <div class="card card-body bg-warning text-white">
            <h6 class="mb-0">
              <i class="ph ph-warning-circle"></i> No momento não exitem etapas
              em andamento.
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import stepsAvaliables from '@/components/flow/StepAvaliable.vue'

export default {
  name: 'CompanyDetails',
  data() {
    return {
      companyId: this.$route.params.code,
      company: null,
      isLoadingSteps: true,
      notAvaliableStep: null,
      avaliableSteps: [],
      avaliableStepsNames: [],
    }
  },
  components: {
    LoadingData,
    stepsAvaliables,
  },
  methods: {
    async getCompany() {
      try {
        this.company = await this.$store.dispatch('getCompany', this.companyId)
        this.$store.dispatch(
          'setPageTitle',
          `Detalhes do Orçamento - ${this.company.NOMEFANTASIA}`,
        )
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getAvaliableStepsOnFlow() {
      try {
        const stepList = await this.$store.dispatch(
          'getAvaliableStepsOnFlow',
          this.companyId,
        )
        if (!stepList.status) {
          this.notAvaliableStep = stepList.message
        }
        if (stepList.data && stepList.data.stepsAvaliables.length > 0) {
          this.avaliableSteps = stepList.data.stepsAvaliables
          // recupera o nome das etapas
          this.avaliableStepsNames = stepList.data.stepsAvaliables.map(
            (step) => {
              return step.NOME
            },
          )
        }
        this.isLoadingSteps = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.getCompany()
    this.getAvaliableStepsOnFlow()
  },
}
</script>
